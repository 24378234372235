import moment from 'moment'

export const JSON_FIELDS_CSV = {
    incompletedPatrolPoints: {
        pt: {
            json_fields: {
                Agenda: 'name',
                'Data de início': 'startDate',
                'Data de fim': 'endDate',
                Empresa: 'account',
                Cliente: 'client',
                Local: 'site',
                'Pontos Visitados': 'checkedPoints',
                'Pontos Não Visitados': 'uncheckedPoints',
            },
            json_data: [
                {
                    name: '',
                    startDate: '',
                    endDate: '',
                    account: '',
                    client: '',
                    site: '',
                    checkedPoints: '',
                    uncheckedPoints: '',
                },
            ],
            json_meta: [
                [
                    {
                        key: 'charset',
                        value: 'utf-8',
                    },
                ],
            ],
            title: 'Relatório de Rondas Agendadas',
            filename: 'rondas_' + moment().format('YYYY-MM-DD'),
        },
        en: {
            json_fields: {
                Schedule: 'name',
                'Start Date': 'startDate',
                'End Date': 'endDate',
                Company: 'account',
                Client: 'client',
                Site: 'site',
                'Checked Points': 'checkedPoints',
                'Unchecked Points': 'uncheckedPoints',
            },
            json_data: [
                {
                    name: '',
                    startDate: '',
                    endDate: '',
                    account: '',
                    client: '',
                    site: '',
                    checkedPoints: '',
                    uncheckedPoints: '',
                },
            ],
            json_meta: [
                [
                    {
                        key: 'charset',
                        value: 'utf-8',
                    },
                ],
            ],
            title: 'Scheduled Patrol Report',
            filename: 'patrols_' + moment().format('YYYY-MM-DD'),
        },
    },
}
