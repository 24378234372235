export const PDF_HEADER = {
    pt: [
        {
            _id: 'name',
            name: 'Nome',
        },
        {
            _id: 'startDate',
            name: 'Data de início',
        },
        {
            _id: 'endDate',
            name: 'Data de fim',
        },
        {
            _id: 'account',
            name: 'Empresa',
        },
        {
            _id: 'client',
            name: 'Cliente',
        },
        {
            _id: 'site',
            name: 'Local',
        },
        {
            _id: 'checkedPoints',
            name: 'Pontos Visitados',
        },
        {
            _id: 'uncheckedPoints',
            name: 'Pontos Não Visitados',
        },
    ],
    en: [
        {
            _id: 'name',
            name: 'Schedule',
        },
        {
            _id: 'startDate',
            name: 'Start Date',
        },
        {
            _id: 'endDate',
            name: 'End Date',
        },
        {
            _id: 'account',
            name: 'Company',
        },
        {
            _id: 'client',
            name: 'Client',
        },
        {
            _id: 'site',
            name: 'Site',
        },
        {
            _id: 'checkedPoints',
            name: 'Checked Points',
        },
        {
            _id: 'uncheckedPoints',
            name: 'Unchecked Points',
        },
    ],
}
